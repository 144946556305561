<script>
</script>
<template>
    <header>
    </header>
    <slot></slot>
    <footer>
    </footer>
</template>
<style scoped>
header {
    height: 100px;
    background-color: #167450;
}

footer {
    height: 100px;
    background-color: black;
}
</style>